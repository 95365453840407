<template>
  <div>
    <b-table-simple class="xac-trung" v-if="Object.keys(data).length > 0">
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th class="text-right">
            <b-form-checkbox
              v-if="isShowCheckSwitchXac"
              v-model="isShowPointXac"
              name="check-button"
              switch
            >
              Xác
            </b-form-checkbox>
            <span v-else>
              Xác
            </span>
          </b-th>
          <b-th class="text-right" v-if="showAmount">Thực Thu</b-th>
          <b-th class="text-right">Trúng</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(value, name, idx) in data" :key="idx">
          <b-td class="type">{{ name }}</b-td>
          <b-td class="info"
            >{{ formatMoney(value.turn_over) }}
            <small v-if="isShowPointXac">
              ({{ formatPoint(value.turn_over_point) }}n)</small
            ></b-td
          >
          <b-td v-if="showAmount" class="info">{{
            formatMoney(value.amount)
          }}</b-td>
          <b-td class="info"
            ><span class="text-primary "
              >{{ formatMoney(value.win) }} ({{
                formatPoint(value.win_point)
              }}n)</span
            ></b-td
          >
        </b-tr>
      </b-tbody>
      <slot name="tfoot"></slot>
    </b-table-simple>
    <b-row v-if="number_win != ''" class="container"
      >Số trúng: {{ number_win }}</b-row
    >
  </div>
</template>
<script>
import { Utilities } from "@/utilities";

export default {
  name: "xac-trung",
  props: {
    data: {
      type: Object,
      default: () => {},
      description: ""
    },
    isShowCheckSwitchXac: {
      type: Boolean,
      default: true
    },
    showAmount: {
      type: Boolean,
      default: false
    },
    number_win: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isShowPointXac: false
    };
  },
  watch: {
    data: function(newData) {
      console.log(newData);
    }
  },
  methods: {
    formatMoney(money) {
      return Utilities.currencyReport(money);
    },
    formatPoint(point) {
      return Utilities.thousandFormat(point);
    }
  }
};
</script>
<style>
.xac-trung .table th,
.xac-trung .table td {
  padding: 0.3rem;
}
.xac-trung .table {
  margin-bottom: 0;
}
.xac-trung .info {
  text-align: right;
}
.xac-trung .type {
  text-align: left;
}
</style>
