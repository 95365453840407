<template>
  <div id="main-app">
    <ZHeader :menu="menu" />
    <router-view />
    <ZFooter :menu="menu" />
  </div>
</template>

<script>
import ZHeader from "@/components/ZHeader";
import ZFooter from "@/components/ZFooter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menu: []
    };
  },
  components: {
    ZHeader,
    ZFooter
  },
  methods: {
    fetchGameMapping() {
      if (Object.keys(this.game).length > 0) return;
      this.$store.dispatch("getGameMapping");
    },
    fetchMenu() {
      // let res = await this.$http.get('menu');
      this.menu = [
        {
          id: 5,
          title: "Dashboard",
          link: "dashboard",
          priority: 1,
          icon: "people-fill",
          children: []
        },
        {
          id: 3,
          title: "B\u00e1o c\u00e1o",
          link: "report",
          priority: 2,
          icon: "clipboard-data",
          children: []
        },
        {
          id: 3,
          title: "Parents",
          link: "parents",
          priority: 3,
          icon: "clipboard-data",
          children: []
        },
        {
          id: 6,
          title: "K\u1ebf to\u00e1n",
          link: "sale",
          priority: 5,
          icon: "pie-chart-fill",
          children: []
        },
        {
          id: 7,
          title: "KQXS",
          link: "kqxs",
          priority: 7,
          icon: "pie-chart-fill",
          children: []
        },
        {
          id: 10,
          title: "setting-group",
          link: "",
          priority: 6,
          icon: "gear-fill",
          children: [
            {
              id: 12,
              title: "\u0110\u1ed5i m\u1eadt kh\u1ea9u",
              link: "changePass",
              priority: 1,
              icon: "people-fill",
              children: []
            }
          ]
        }
      ];
    }
  },
  computed: {
    ...mapGetters(["game"])
  },
  created() {
    this.fetchGameMapping();
    this.fetchMenu();
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 60px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.table th,
.table td {
  padding: 0.3rem !important;
}
#user-info-modal footer {
  display: none;
}
</style>
