import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/Login.vue"),
    meta: {
      rule: ["*"],
      authPage: true
    }
  },
  {
    path: "",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue")
      },
      {
        path: "/report",
        name: "report",
        component: () => import("@/views/report/Report.vue")
      },
      {
        path: "/parents",
        name: "parents",
        component: () => import("@/views/Parents.vue")
      },
      {
        path: "/kqxs",
        name: "kqxs",
        component: () => import("@/views/Kqxs.vue")
      },
      {
        path: "/report/:userId/player/:playerId",
        name: "reportPlayerDetail",
        component: () => import("@/views/report/ReportDetail.vue"),
        meta: {
          parent: "report"
        }
      },
      {
        path: "/sale",
        name: "sale",
        component: () => import("@/views/Sale.vue")
      },
      {
        path: "/change-pass",
        name: "changePass",
        component: () => import("@/views/ChangePass.vue"),
        meta: {
          rule: ["*"],
          parent: "userGroup"
        }
      }
    ],
    meta: {
      rule: ["*"]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
