<template>
  <b-container class="report">
    <div class="px-3">
      <b-row align-v="center" class="mt-2">
        <b-col cols="12" class="d-lg-none text-center">
          <h4 class="mr-auto">Báo cáo</h4>
        </b-col>
        <b-col cols="12" class="mb-2 text-right">
          <b-input-group size="sm" class="mw-50">
            <b-input-group-append>
              <span class="input-group-text">Tài khoản</span>
            </b-input-group-append>
            <b-form-input
              v-model="reportUser"
              placeholder="Nhập tài khoản"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="6" class="mb-2 text-right">
          <b-input-group size="sm" class="mw-50">
            <flat-pickr
              class="form-control form-control-sm"
              :config="configPickr"
              v-model="date"
            >
            </flat-pickr>
            <b-input-group-append>
              <button
                class="btn input-group-text"
                type="button"
                title="Toggle"
                data-toggle
              >
                <b-icon
                  icon="calendar2-check"
                  scale="1"
                  variant="secondary"
                ></b-icon>
              </button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="6" class="mb-2 text-left">
          <b-form-select v-model="game_selected" size="sm">
            <b-form-select-option :value="-1">Tất cả</b-form-select-option>
            <b-form-select-option :value="1">Bắc</b-form-select-option>
            <b-form-select-option :value="2">Trung</b-form-select-option>
            <b-form-select-option :value="3">Nam</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="12" class="mb-2 text-right">
          <b-button @click="viewReport" class="w-100" variant="outline-primary"
            >Xem</b-button
          >
        </b-col>
      </b-row>
    </div>
    <div class="content px-3">
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th class="text-left">Khách</b-th>
            <b-th class="text-right">Tiền xác</b-th>
            <b-th class="text-right">Thực thu</b-th>
            <b-th class="text-right">Trúng</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(item, itemIndex) in tableData">
            <b-tr :key="'a' + itemIndex">
              <b-td rowspan="2" class="name">
                <router-link
                  class="text-second_"
                  :to="{
                    name: 'reportPlayerDetail',
                    params: { playerId: item.player_id, userId: item.user_id },
                    query: { date: date }
                  }"
                  >{{ item.player }}
                </router-link>
              </b-td>
              <router-link
                tag="td"
                class="text-right"
                :to="{
                  name: 'reportPlayerDetail',
                  params: { playerId: item.player_id, userId: item.user_id },
                  query: { date: date }
                }"
              >
                {{ format(item.turn_over) }}
              </router-link>
              <router-link
                tag="td"
                class="text-right"
                :to="{
                  name: 'reportPlayerDetail',
                  params: { playerId: item.player_id, userId: item.user_id },
                  query: { date: date }
                }"
              >
                {{ format(item.amount) }}
              </router-link>
              <router-link
                tag="td"
                class="text-right"
                :to="{
                  name: 'reportPlayerDetail',
                  params: { playerId: item.player_id, userId: item.user_id },
                  query: { date: date }
                }"
              >
                {{ format(item.win) }}
              </router-link>
            </b-tr>
            <b-tr :key="itemIndex">
              <router-link
                tag="td"
                class="text-right"
                colspan="7"
                :to="{
                  name: 'reportPlayerDetail',
                  params: { playerId: item.player_id, userId: item.user_id },
                  query: { date: date }
                }"
              >
                Thắng thua: <b v-html="formatWinloss(item.winloss)"></b>
              </router-link>
            </b-tr>
          </template>
        </b-tbody>
        <b-tfoot class="ml-3 mr-3">
          <b-tr>
            <b-td colspan="7" class="text-right summary">
              <span class="text-danger">Ăn</span>
              <span class="text-primary"> | Thua</span>:
              <b v-html="formatWinloss(wlAmount)"></b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </div>
    <infinite-loading :identifier="infiniteId" @infinite="fetchAmountReport">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </b-container>
</template>
<script>
import moment from "moment";
import { Utilities } from "@/utilities";
import InfiniteLoading from "vue-infinite-loading";
import predefinedRangesPlugin from "@/components/flatpicker/plugin/presetRange.js";
import "@/components/flatpicker/plugin/presetRange.css";

export default {
  name: "Report",
  components: {
    InfiniteLoading
  },
  data() {
    return {
      configPickr: {
        allowInput: false,
        disableMobile: true,
        dateFormat: "d-m-Y",
        mode: "range",
        defaultDate: moment().format("DD-MM-YYYY"),
        plugins: [new predefinedRangesPlugin()],
        wrap: true
      },
      reportUser: "",
      game_selected: -1,
      date: null,
      page: 1,
      tableData: [],
      wlAmount: 0,
      infiniteId: +new Date()
    };
  },
  computed: {
    dateFromPicker: function() {
      let date = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      };

      if (this.date) {
        if (this.date.includes(" -> ")) {
          let ds = this.date.split(" -> ");
          date = {
            from: moment(ds[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
            to: moment(ds[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          };
        } else {
          let d = moment(this.date, "DD-MM-YYYY").format("YYYY-MM-DD");
          date = {
            from: d,
            to: d
          };
        }
      }
      return date;
    }
  },
  watch: {
    date: async function(newDate) {
      let r = {
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: { ...this.$router.history.current.query, date: newDate }
      };

      await this.$nextTick();

      if (this.$router.history.current.query.date == this.date) return;

      this.resetPage();
      this.$router.replace(r);
    },
    game_selected: async function(newVal) {
      newVal;
      await this.$nextTick();
      this.resetPage();
    }
  },
  created() {
    this.bindDate();
  },
  methods: {
    viewReport() {
      this.resetPage();
    },
    async fetchAmountReport($state) {
      if (this.reportUser == "") {
        $state.complete();
        return;
      }

      let res = await this.$http.get("/report/summary", {
        params: {
          ...this.dateFromPicker,
          page: this.page,
          game: this.game_selected,
          user: this.reportUser
        }
      });

      if (res.data.code == 0) {
        if (res.data.data.length == 0) {
          $state.complete();
          return;
        }

        let total = res.data.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.winloss,
          0
        );

        this.tableData = [...this.tableData, ...res.data.data];
        this.wlAmount = total;
        this.page++;

        if (res.data.data.length < 20) {
          $state.complete();
        } else {
          $state.loaded();
        }
      }
    },
    resetPage() {
      this.page = 1;
      this.tableData = [];
      this.wlAmount = 0;
      this.infiniteId++;
    },
    bindDate() {
      let date = this.$route.query.date
        ? this.$route.query.date
        : moment().format("DD-MM-YYYY");
      this.date = date;
    },
    format(money) {
      return Utilities.currencyReport(money);
    },
    formatWinloss(money) {
      let cls = money < 0 ? "text-danger" : "text-primary";
      let m =
        "<span class='" +
        cls +
        "'>" +
        Utilities.currencyReport(money) +
        "</span>";
      return m;
    }
  }
};
</script>
<style>
.report .bt2 {
  border-top: 2px solid #dcdcdc;
}
.report .name {
  white-space: pre-wrap !important;
  text-align: left;
}
</style>
