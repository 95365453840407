<template>
  <div class="report px-3 mt-4">
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Nam" active>
            <b-button-group>
              <b-button>Check</b-button>
              <b-button variant="info">Crawler</b-button>
              <b-button variant="warning">Compare</b-button>
              <b-button variant="success">Analysis</b-button>
            </b-button-group>
          </b-tab>
          <b-tab title="Trung">
            <b-button-group>
              <b-button>Check</b-button>
              <b-button variant="info">Crawler</b-button>
              <b-button variant="warning">Compare</b-button>
              <b-button variant="success">Analysis</b-button>
            </b-button-group>
          </b-tab>
          <b-tab title="Bac">
            <b-button-group>
              <b-button>Check</b-button>
              <b-button variant="info">Crawler</b-button>
              <b-button variant="warning">Compare</b-button>
              <b-button variant="success">Analysis</b-button>
            </b-button-group>
          </b-tab>
        </b-tabs>
      </b-card>
      <div v-for="(tableData, tableIndex) in datas" :key="tableIndex">
        <h3>Table {{ tableIndex + 1 }}</h3>
        <table border="1">
          <thead>
            <tr>
              <th>Category</th>
              <th>Numbers</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(numbers, category) in tableData[0]" :key="category">
              <td>{{ category }}</td>
              <td>
                <!-- <input type="text" v-for="(number, index) in numbers" :key="index" :value="number" readonly /> -->

                <!-- <br v-if="category === 'giai-tu' && (index + 1) % 3 === 0"> -->

                <!-- <template v-for="(number, index) in numbers">
                        <input type="text" :value="number" :key="index">
                        <template v-if="key === 'giai-tu' && index % 3 === 2"><br></template>
                    </template> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-button variant="primary" @click="getDataFromHtml">Lấy lại dữ liệu từ HTML</b-button>

    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      datas: [
        [
          {
            "dac-biet": ["12759"],
            "giai-nhat": ["32594"],
            "giai-nhi": ["78172", "76635"],
            "giai-ba": ["00308", "07679", "22087", "86586", "82661", "90324"],
            "giai-tu": ["9763", "6386", "0258", "1675"],
            "giai-nam": ["5532", "4873", "9528", "1753", "9654", "4449"],
            "giai-sau": ["519", "359", "467"],
            "giai-bay": ["26", "99", "54", "53"]
          }
        ],
        [
          {
            "dac-biet": ["12759"],
            "giai-nhat": ["32594"],
            "giai-nhi": ["78172", "76635"],
            "giai-ba": ["00308", "07679", "22087", "86586", "82661", "90324"],
            "giai-tu": ["9763", "6386", "0258", "1675"],
            "giai-nam": ["5532", "4873", "9528", "1753", "9654", "4449"],
            "giai-sau": ["519", "359", "467"],
            "giai-bay": ["26", "99", "54", "53"]
          }
        ]
      ]
    };
  },
  methods: {
    getDataFromHtml() {
      const inputs = document.querySelectorAll('#MB0 input');
      let extractedData = {};

      // Lấy toàn bộ giá trị từ input fields và nhóm lại theo vị trí
      let count = 0;
      for (let [key, numbers] of Object.entries(this.datas)) {
        extractedData[key] = [];
        for (let i = 0; i < numbers.length; i++) {
          extractedData[key].push(inputs[count].value);
          count++;
        }
      }

      console.log('Dữ liệu lấy từ HTML:', extractedData);
    }
  }
};
</script>
