import httpApi from "@/http/axios";

const state = {
  user: null
};

const mutations = {
  setUserData(state, userData) {
    state.user = userData;
    localStorage.setItem("userInfo", JSON.stringify(userData));
  },
  clearUserData() {
    localStorage.removeItem("userInfo");
    window.document.cookie =
      "laravel_session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
};

const actions = {
  login({ commit }, payload) {
    return httpApi
      .get(`//${process.env.VUE_APP_API}/sanctum/csrf-cookie`)
      .then(response => {
        console.log(response);
        return httpApi
          .post(`//${process.env.VUE_APP_API}/secret/login`, payload)
          .then(({ data }) => {
            commit("setUserData", data.data);
          })
          .catch(err => {
            if (err.response && err.response.status == 403) {
              return Promise.reject("Sai thông tin đăng nhập!");
              // console.log(err.response.data);
              // console.log(err.response.status);
              // console.log(err.response.headers);
            }
          });
      });
  },

  logout({ commit }) {
    commit("clearUserData");
  },

  verify_auth({ commit }) {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) return;

    return httpApi
      .get("verify")
      .then(res => {
        if (res.data.code == 0) console.log("verify auth => OK");
      })
      .catch(({ response }) => {
        console.log("error", response);
        commit("clearUserData");
      });
  }
};

const getters = {
  isLogged: state => !!state.user,
  currentUser: state => state.user
};

export default {
  state,
  actions,
  mutations,
  getters
};
