<template>
  <div class="footer d-sm-none">
    <b-nav pills>
      <b-nav-item
        class="nav-item-icon-text"
        :to="{ name: item.link }"
        :active="isMenuActive(item)"
        v-for="(item, indexItem) in linkMenu"
        :key="indexItem"
      >
        <div
          v-if="item.link == 'betSms'"
          class="d-flex flex-column align-items-center menu-item add-bet"
        >
          <div class="col-center-sub left">&nbsp;</div>
          <div class="col-center-main">
            <img class="icon" />
            <img src="@/assets/icon-centerpoint-light.svg" class="bg-icon" />
          </div>
          <div class="col-center-sub right">&nbsp;</div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center menu-item pt-1 item"
        >
          <b-icon :icon="item.icon"></b-icon>
          <span class="title">{{ item.title }}</span>
        </div>
      </b-nav-item>

      <b-nav-item
        v-b-modal.user-info-modal
        class="nav-item-icon-text"
        :active="isMenuActive({ link: 'userGroup' })"
      >
        <div class="d-flex flex-column align-items-center menu-item pt-1 item">
          <b-icon icon="gear-fill"></b-icon>
          <span class="title">Cài đặt</span>
        </div>
      </b-nav-item>
    </b-nav>
    <div class="user-settingxx">
      <b-modal ref="user-info-modal" id="user-info-modal" centered>
        <template #modal-header>
          <span>{{ currentUser.name.toUpperCase() }}</span>
          <small>Ngày hết hạn: {{ expire_date }}</small>
        </template>
        <div class="app-version">
          <small>Phiên bản: {{ version }}</small>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider"
          />
        </div>
        <div
          v-for="(item, indexItem) in linkConfig"
          :key="indexItem"
          class="user-setting-content"
        >
          <a @click="hideModal(item)">{{ item.title }}</a>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="dropdown-divider"
          />
        </div>
        <div class="user-setting-content">
          <a @click="logout">
            <b-icon icon="box-arrow-left" aria-hidden="true"></b-icon> Đăng xuất
          </a>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["menu"],
  components: {},
  data() {
    return {
      linkMenu: [],
      linkConfig: [],
      version: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    expire_date: function() {
      if (typeof this.currentUser.expired_in == "string") {
        return this.currentUser.expired_in;
      }

      let expries = moment()
        .add(this.currentUser.expired_in - 1, "days")
        .format("DD-MM-YYYY");
      return expries;
    }
  },
  watch: {
    menu: function(m) {
      this.linkMenu = m.filter(function(el) {
        return el.title != "setting-group";
      });
      this.linkConfig = m.filter(function(el) {
        return el.title == "setting-group";
      })[0].children;
    }
  },
  methods: {
    logout() {
      let vm = this;
      vm.$http.post("//" + process.env.VUE_APP_API + "/logout").then(res => {
        if (res.data.code == 0) {
          vm.$store.dispatch("logout");
          window.location.reload();
        }
      });
    },
    hideModal(item) {
      this.$refs["user-info-modal"].hide();
      this.$router.push({ name: item.link });
    },
    isMenuActive(item) {
      let routerName = this.$route.name;
      const routeParent = this.$route.meta
        ? this.$route.meta.parent
        : undefined;

      return (
        item.link && (routerName === item.link || routeParent === item.link)
      );
    }
  },
  mounted() {
    let m = this.menu.filter(function(el) {
      return el.title != "setting-group";
    });
    let c = this.menu.filter(function(el) {
      return el.title == "setting-group";
    })[0].children;

    m.sort(function(a, b) {
      return a.priority - b.priority;
    });

    this.linkMenu = m;
    this.linkConfig = c;
  }
};
</script>

<style>
.footer {
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  background-color: #e0e0e0;
  box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 6%);
  min-height: calc(50px + (env(safe-area-inset-bottom)) * 1.1);
  transition: all 350ms ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}
.footer .add-bet img.icon {
  content: url("/img/icon-taisan-inactive-light.svg");
}
.footer .router-link-active img.icon {
  content: url("/img/icon-taisan-active-light.svg");
}
.footer .add-bet {
  width: 100%;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -12%);
  position: relative;
}
.footer .add-bet .col-center-main {
  width: 70px;
  left: 50%;
  z-index: 1;
}
.footer .add-bet .col-center-sub {
  width: calc(50% - 30px);
  background: #fff;
  position: absolute;
  height: 50px;
  z-index: 2;
}
.footer .add-bet .col-center-sub.left {
  left: -2px;
  top: 3px;
}
.footer .add-bet .col-center-sub.right {
  right: -2px;
  top: 3px;
}
.footer .add-bet .icon {
  height: 60px;
  width: 54px;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -30%);
  position: relative;
}
.footer .add-bet .bg-icon {
  top: -32px !important;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}
.footer .nav-pills .nav-link {
  border-radius: 0px;
}
.footer .nav-pills .nav-link .item {
  background-color: #fff;
}
.footer .nav-link {
  padding: 0;

  height: 50px;
}
.footer .dropdown-toggle::after {
  display: none;
}
.footer .title {
  font-size: 0.7rem;
  font-weight: 500;
  opacity: 0.7;
}
.footer .nav-item {
  flex: 1;
}
.footer .nav-item-icon-text {
  line-height: 1.8;
}
.footer .b-dropdown-text {
  display: block;
}
.footer .expired {
  line-height: 0px;
  width: 240px;
  margin-top: -15px;
}
.menu-item {
  /* icon inherit from parent */
  color: #1f1f1f;
  font-size: 1.3rem;
  /* icon inherit from parent */
}
.active .menu-item {
  color: #da4453 !important;
}
.nav-pills .nav-link.active {
  background: none;
}
.nav-item {
  align-self: center;
}
.nav-pills .show > .nav-link {
  background: none;
}
#user-info-modal .modal-header {
  background-color: #da4453 !important;
  color: #fff;
}
</style>
