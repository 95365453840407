<template>
  <b-container class="report">
    <div class="px-3">
      <b-row align-v="center" class="mt-2">
        <b-col cols="12" class="mb-2 text-right">
          <b-input-group size="sm" class="mw-50">
            <b-input-group-append>
              <span class="input-group-text">Tài khoản</span>
            </b-input-group-append>
            <b-form-input
              v-model="user"
              placeholder="Nhập tài khoản"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" class="mb-2 text-right">
          <b-button @click="findParents" class="w-100" variant="outline-primary"
            >Xem</b-button
          >
        </b-col>
      </b-row>
    </div>
    <div class="content px-3">
      <b-table striped hover :items="datas"></b-table>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "Report",
  components: {},
  data() {
    return {
      user: "",
      datas: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    async findParents() {
      let res = await this.$http.get("/user/parents", {
        params: {
          user: this.user,
        },
      });
      if (res.data.code == 0) {
        this.datas = res.data.data;
      }
    },
  },
};
</script>