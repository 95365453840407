var numeral = require("numeral");
var moment = require("moment");

var Utilities = (function() {
  return {
    currency: function(numberString) {
      var resultString = numberString + "",
        x = resultString.split("."),
        x1 = x[0],
        x2 = x.length > 1 ? "." + x[1] : "",
        rgxp = /(\d+)(\d{3})/;

      while (rgxp.test(x1)) {
        x1 = x1.replace(rgxp, "$1" + "," + "$2");
      }

      return x1 + x2 + "";
    },
    thousandFormat: function(money) {
      return numeral(money).format("0,0");
    },
    baoSoFormat: function(money) {
      money = parseFloat(money);
      money = money / 1000.0;

      return numeral(money).format("0,0");
    },
    currencyReport: function(money) {
      money = parseFloat(money);

      money = money / 1000.0;

      let tmp = money + "";
      tmp = tmp.split(".");

      if (tmp.length > 1 && tmp[1].length > 2) {
        tmp[1] = tmp[1].substring(0, 2);
      }

      tmp = tmp.join(".");
      money = parseFloat(tmp);

      money = numeral(money).format("0,0");

      return money;
    },
    // To get the correct viewport width based on  http://andylangton.co.uk/articles/javascript/get-viewport-size-javascript/
    getViewPort: function() {
      var e = window,
        a = "inner";
      if (!("innerWidth" in window)) {
        a = "client";
        e = document.documentElement || document.body;
      }

      return {
        width: e[a + "Width"],
        height: e[a + "Height"]
      };
    },

    numberFormatK: function(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
      }
      return num;
    },
    setStorage: function(name, value, expires) {
      if (localStorage.key(name) != null) localStorage.removeItem(name);

      var d = moment()
        .add(expires, "s")
        .format("YYYYMMDDHHmmss");
      var data = { value: value, time: d };
      localStorage.setItem(name, JSON.stringify(data));
    },
    getStorage: function(name) {
      var data = JSON.parse(localStorage.getItem(name));
      if (data == null || moment().format("YYYYMMDDHHmmss") > data.time)
        return "";
      return data.value;
    }
  };
})();

export { Utilities };
