<template>
  <div class="d-none d-sm-block header-top">
    <div class="container">
      <div class="d-flex align-items-center mr-3">
        <b-navbar toggleable="lg" type="dark" variant="dark">
          <b-navbar-brand href="#">
            <img
              alt="Logo"
              src="@/assets/logo.png"
              class="max-h-35px"
              height="35"
            />
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item
                :to="{ name: item.link }"
                :active="isMenuActive(item)"
                v-for="(item, indexItem) in linkMenu"
                :key="indexItem"
                >{{ item.title }}</b-nav-item
              >
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
      <div class="topbar">
        <div class="topbar-item">
          <div
            v-b-toggle.user-action
            class="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2"
            id="kt_quick_user_toggle"
          >
            <div class="d-flex flex-column text-right pr-sm-3">
              <span
                class="text-white opacity-50 font-weight-bold font-size-sm d-none d-sm-inline"
                >{{ currentUser.name }}</span
              >
              <span
                class="text-white font-weight-bolder font-size-sm d-none d-sm-inline"
                >{{ currentUser.role[0] }}</span
              >
            </div>
            <span class="symbol symbol-35">
              <span
                class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"
                >{{ currentUser.name.substr(0, 1) }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar id="user-action" :title="currentUser.name" shadow backdrop right>
      <div
        class="px-3 py-2 navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon"
      >
        <div class="text-right text-muted expires_date">
          Ngày hết hạn: {{ expire_date }}
        </div>
        <div
          class="navi-item my-2"
          v-for="(item, indexItem) in linkConfig"
          :key="indexItem"
        >
          <router-link
            :to="{ name: item.link }"
            :class="'navi-link ' + (isMenuActive(item) ? 'active' : '')"
          >
            <span class="navi-icon mr-4">
              <span class="svg-icon svg-icon-lg">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-right.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                    <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                    ></path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </span>
            <span class="navi-text font-weight-bolder font-size-lg">{{
              item.title
            }}</span>
          </router-link>
        </div>
        <div class="navi-item my-2">
          <a href="javascript:;" @click="logout" class="navi-link">
            <span class="navi-icon mr-4">
              <span class="svg-icon svg-icon-lg">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-right.svg-->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                    <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"
                    ></path>
                  </g>
                </svg>
                <!--end::Svg Icon-->
              </span>
            </span>
            <span class="navi-text font-weight-bolder font-size-lg">Thoát</span>
          </a>
        </div>
      </div>
      <template #footer>
        <small>Phiên bản: {{ version }}</small>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["menu"],
  components: {},
  data() {
    return {
      linkMenu: [],
      linkConfig: [],
      version: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    expire_date: function() {
      if (typeof this.currentUser.expired_in == "string") {
        return this.currentUser.expired_in;
      }

      let expries = moment()
        .add(this.currentUser.expired_in - 1, "days")
        .format("DD-MM-YYYY");
      return expries;
    }
  },
  watch: {
    menu: function(m) {
      this.linkMenu = m.filter(function(el) {
        return el.title != "setting-group";
      });
      this.linkConfig = m.filter(function(el) {
        return el.title == "setting-group";
      })[0].children;

      this.linkMenu.sort(function(a, b) {
        return a.priority - b.priority;
      });
    }
  },
  methods: {
    logout() {
      let vm = this;
      vm.$http.post("//" + process.env.VUE_APP_API + "/logout").then(res => {
        if (res.data.code == 0) {
          vm.$store.dispatch("logout");
          window.location.reload();
        }
      });
    },
    isMenuActive(item) {
      let routerName = this.$route.name;
      const routeParent = this.$route.meta
        ? this.$route.meta.parent
        : undefined;

      return (
        item.link && (routerName === item.link || routeParent === item.link)
      );
    }
  },
  mounted() {
    let m = this.menu.filter(function(el) {
      return el.title != "setting-group";
    });
    let c = this.menu.filter(function(el) {
      return el.title == "setting-group";
    })[0].children;

    m.sort(function(a, b) {
      return a.priority - b.priority;
    });

    this.linkMenu = m;
    this.linkConfig = c;
  }
};
</script>

<style>
.expires_date {
  font-size: 0.9rem;
}
.header-top {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #3699ff;
}
.header-top .container {
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header-top .container img {
  vertical-align: middle;
  border-style: none;
}
.header-top .container .header-tabs {
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.header-top .container .header-tabs .nav-item {
  padding: 0;
  margin-right: 0.25rem;
  position: relative;
}
.header-top .container .header-tabs .nav-item .nav-link.active {
  background-color: #fff;
  opacity: 1;
  color: #181c32;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}
.header-top .container .header-tabs .nav-item .nav-link {
  margin: 0;
  padding: 0.85rem 1.25rem;
  font-weight: 500;
  opacity: 0.8;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
  display: flex;
  align-items: center;
}
.header-top .container .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 1rem;
  background-color: #3699ff;
  z-index: 1000;
}

.topbar {
  display: flex;
  align-items: stretch;
  padding: 0;
}
.topbar .topbar-item {
  display: flex;
  align-items: center;
  height: 70px;
}
.btn.btn-hover-transparent-white {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
}
.btn:not(.btn-text) {
  cursor: pointer;
}
.btn {
  outline: 0 !important;
  vertical-align: middle;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.font-size-sm {
  font-size: 0.925rem;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}
.symbol.symbol-35 .symbol-label {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3f4254;
  background-color: #f3f6f9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}
.font-size-h5 {
  font-size: 1.25rem !important;
}
.bg-white-o-30 {
  background-color: rgba(255, 255, 255, 0.12) !important;
}
.btn.btn-hover-transparent-white:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}
.btn.focus,
.btn:focus:not(.btn-text),
.btn:hover:not(.btn-text) {
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
.navi {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}
.navi .navi-item {
  padding: 0;
  display: block;
  list-style: none;
}
.navi.navi-light-icon .navi-item .navi-link.active {
  transition: all 0.15s ease;
  color: #3699ff;
}
.navi.navi-link-rounded .navi-item .navi-link {
  border-radius: 0.42rem;
}
.navi .navi-item .navi-link {
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
}
.navi.navi-light-icon .navi-item .navi-link.active .navi-icon {
  transition: all 0.15s ease;
}
.navi.navi-icon-center .navi-item .navi-link .navi-icon {
  text-align: center;
}
.navi .navi-item .navi-link .navi-icon {
  flex: 0 0 2rem;
}
.navi.navi-light-icon .navi-item .navi-link.active .navi-text {
  color: #3699ff;
}
.navi.navi-light-icon .navi-item .navi-link.active .navi-text {
  transition: all 0.15s ease;
}
.navi.navi-active .navi-item .navi-link.active {
  background-color: #f3f6f9;
  transition: all 0.15s ease;
  color: #3699ff;
}
.navi.navi-hover .navi-item .navi-link:hover {
  background-color: #f3f6f9;
}
.navi.navi-light-icon .navi-item .navi-link:hover {
  transition: all 0.15s ease;
  color: #3699ff;
}
.navi.navi-link-rounded .navi-item .navi-link {
  border-radius: 0.42rem;
}
</style>
