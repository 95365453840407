import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "./plugins/persistent";

import moduleAuth from "./auth/auth.js";
import moduleMapping from "./mapping";

Vue.use(Vuex);

const plugins = [
  createPersistedState({
    // Specify here which modules should be persistent:
    modules: ["mapping"]
  })
];

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: moduleAuth,
    mapping: moduleMapping
  },
  plugins: plugins
});
