<template>
  <div id="app" class="z-theme-light">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      refreshing: false,
      registration: null
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", this.update);
    }
  },
  methods: {
    async update() {
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 60px;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.table th,
.table td {
  padding: 0.5rem !important;
}
.form-control.flatpickr-input:disabled,
.form-control.flatpickr-input[readonly] {
  background-color: #ffffff;
}
.bao-so .input-group-sm > .form-control:not(textarea),
.bao-so .input-group-sm > .custom-select,
.customer-detail .input-group-sm > .form-control:not(textarea),
.customer-detail .input-group-sm > .custom-select,
.bet-list .input-group-sm > .form-control:not(textarea),
.bet-list .input-group-sm > .custom-select,
.bet-sms .input-group-sm > .form-control:not(textarea),
.bet-sms .input-group-sm > .custom-select {
  height: auto;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.table th {
  border-top: 0px !important;
}
.table thead th {
  border-bottom: 1px solid #dee2e6 !important;
}
a:hover {
  text-decoration: unset;
}
.z-theme-light .bet-list .link h5 {
  color: #3f4254 !important;
}
.z-theme-light .select_box {
  position: relative;
}
.z-theme-light .select_box:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #9a9b9c;
  position: absolute;
  top: 35%;
  right: 25px;
  content: "";
}
.z-theme-light .select_box select {
  position: relative;
  background: none;
}
.z-theme-light input.free-lr {
  border-left: 0px;
  border-right: 0px;
}
.z-theme-light input.free-l {
  border-left: 0px;
}
.z-theme-light .input-group-text {
  border-right: 0px;
}
.z-theme-light .bet-list .rounded-circle {
  border-radius: 25% !important;
}
.z-theme-light .bet-list .link h5 {
  color: rgb(34, 43, 69) !important;
}
.z-theme-light .bet-list .link small {
  color: rgb(93 95 99) !important;
}
.z-theme-light .list-group {
  border-radius: 0px;
}
.z-theme-light .list-group .list-group-item {
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #f7f7f7;
}
.z-theme-light .list-group .list-group-item:last-child {
  border: 0px;
}
.z-theme-light .btn.btn-primary {
  color: #fff;
  background-color: #3699ff;
  border-color: #3699ff;
}
.z-theme-light .btn.btn-danger {
  color: #fff;
  background-color: #f64e60;
  border-color: #f64e60;
}
.z-theme-light .btn.btn-success {
  color: #fff;
  background-color: #1bc5bd;
  border-color: #1bc5bd;
}

body:has(> div.z-theme-dark) {
  background-color: #000 !important;
}

.z-theme-dark,
.z-theme-dark .table {
  color: #f5f5f5 !important;
}

.z-theme-dark .button-back button {
  border-color: #000;
}
.z-theme-dark .btn-primary {
  background-color: #3699ff !important;
}
.z-theme-dark .btn-success {
  background-color: #34a57b !important;
}
.z-theme-dark .btn-danger {
  background-color: #f64e60 !important;
}

.z-theme-dark .footer {
  background: #323232;
}
.z-theme-dark .footer .menu-item {
  color: #bebebe;
}
.z-theme-dark .footer .active .menu-item {
  color: #ff3747;
}
.z-theme-dark .short-cut {
  bottom: 59px;
  right: 0px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: #202020 !important;
  border: 1px solid #434343;
  color: #bebebe;
}

.z-theme-dark .bet-list .list-group-item {
  background-color: #2b2b2b !important;
  border: 0px;
  border-bottom: 1px solid #555;
}
.z-theme-dark .form-control.flatpickr-input:disabled,
.z-theme-dark .form-control.flatpickr-input[readonly],
.z-theme-dark .custom-select,
.z-theme-dark .input-group-text,
.z-theme-dark .bet-list input,
.z-theme-dark .customer-detail input,
.z-theme-dark .bet-sms input {
  background-color: #000 !important;
  border: 0px;
  border-radius: 0px !important;
  border-bottom: 1px solid #fff;
  color: #f5f5f5;
}
.z-theme-dark .input-group-text {
  padding: 0rem 0.5rem;
}

.z-theme-dark .customer-detail .content .input-group-text,
.z-theme-dark .customer-detail .content input {
  background-color: #000 !important;
  border: 0px;
  border-radius: 0px !important;
  background-color: #2b2b2b !important;
  color: #f5f5f5;
}
.z-theme-dark .customer-detail .content input {
  border-bottom: 1px solid #fff;
}

.z-theme-dark .bet-list .form-control:focus,
.z-theme-dark .bet-list .custom-select:focus {
  box-shadow: none;
}

.z-theme-dark .bet-list .rounded-circle {
  border-radius: 25% !important;
}
.z-theme-dark .bet-list .link h5,
.z-theme-dark .bet-list .link small {
  color: #f5f5f5 !important;
}
.z-theme-dark .bet-list .bet-item .card-profile-stats > div .heading,
.z-theme-dark .bet-list .bet-item .card-profile-stats > div .description {
  color: #f5f5f5;
}

.z-theme-dark .bet-sms .card-group .card .filter {
  background-color: #000 !important;
}
.z-theme-dark .bet-sms .card-group .card {
  background-color: #2b2b2b !important;
}

.z-theme-dark .bet-sms #sms-editor:focus {
  outline: none;
}

.z-theme-dark .report .content,
.z-theme-dark .report-detail .content,
.z-theme-dark .customer-list .content,
.z-theme-dark .customer-detail .content {
  background-color: #2b2b2b !important;
}
.z-theme-dark .report .table .summary,
.z-theme-dark .report-detail .table .summary {
  background-color: #434343 !important;
  border-top: 0px;
}
.z-theme-dark .table thead th {
  border-bottom: 1px solid #dee2e6;
}

.z-theme-dark .select_box {
  position: relative;
}
.z-theme-dark .select_box:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  position: absolute;
  top: 40%;
  right: 20px;
  content: "";
}
.z-theme-dark .select_box select {
  position: relative;
  background: none;
}
</style>
