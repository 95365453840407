<template>
  <b-container class="report-detail">
    <div class="px-3 mb-3">
      <b-row align-v="center" class="mt-2">
        <b-col cols="1" class="d-sm-none">
          <button-back
            :link="{ name: 'report', query: { date: date } }"
          ></button-back>
        </b-col>
        <b-col md="3" sm="12" cols="11" class="text-center text-light">
          <h5 class="d-inline-flex text-muted">
            {{ playerName }} ({{ total_sms }} tin)
          </h5>
        </b-col>
        <b-col md="3" sm="6" cols="5" class="mb-2">
          <b-form-select
            size="sm"
            v-model="game_selected"
            :options="games"
          ></b-form-select>
        </b-col>
        <b-col md="3" sm="6" cols="7" class="mb-2">
          <flat-pickr
            class="form-control form-control-sm"
            :config="configPickr"
            v-model="date"
          >
          </flat-pickr>
        </b-col>
      </b-row>
    </div>
    <div class="content px-3">
      <xac-trung
        class=""
        :data="reportTotal"
        :showAmount="true"
        :isShowCheckSwitchXac="false"
      >
        <template v-slot:tfoot>
          <b-tfoot>
            <b-tr>
              <b-td colspan="2" class="text-right summary">
                {{ summaryDate }}
              </b-td>
              <b-td colspan="2" class="text-right summary">
                <b>
                  <span class="text-danger">Ăn</span> |
                  <span class="text-primary ">Thua</span> :
                  <span :class="thu_tra < 0 ? 'text-danger' : 'text-primary'">{{
                    formatMoney(thu_tra)
                  }}</span>
                </b>
              </b-td>
            </b-tr>
          </b-tfoot>
        </template>
      </xac-trung>
      <div class="sms-content" v-for="(item, idx) in reportData" :key="idx">
        <div class="title-sms ml-2">
          <b class="text-danger">{{ idx + 1 }}) </b>
          <router-link
            :to="{
              name: 'betSms',
              query: { smsid: item.id, origin: 'reportPlayerDetail' }
            }"
            class="sms"
          >
            {{
              item.sms.length > 90
                ? item.sms.substring(0, 90) + " ..."
                : item.sms
            }}
          </router-link>
        </div>
        <xac-trung
          class="card-border-bottom text-left"
          :data="item.detail"
          :number_win="item.number_win"
          :isShowCheckSwitchXac="false"
        ></xac-trung>
      </div>
      <infinite-loading :identifier="infiniteId" @infinite="fetchPlayerDetail">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import XacTrung from "@/components/XacTrung";
import InfiniteLoading from "vue-infinite-loading";
import { Utilities } from "@/utilities";
import predefinedRangesPlugin from "@/components/flatpicker/plugin/presetRange.js";
import "@/components/flatpicker/plugin/presetRange.css";

export default {
  data() {
    return {
      configPickr: {
        allowInput: false,
        disableMobile: true,
        dateFormat: "d-m-Y",
        mode: "range",
        defaultDate: moment().format("DD-MM-YYYY"),
        plugins: [new predefinedRangesPlugin()]
      },
      date: null,
      playerName: "",
      page: 1,
      reportData: [],
      reportTotal: {},
      thu_tra: 0,
      games: [],
      game_selected: null,
      total_sms: 0,
      infiniteId: +new Date()
    };
  },
  components: {
    XacTrung,
    InfiniteLoading
  },
  watch: {
    game: function(games) {
      this.bindGameOpts(games);
    },
    date: async function(newDate) {
      let r = {
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: { ...this.$router.history.current.query, date: newDate }
      };

      await this.$nextTick();

      if (this.$router.history.current.query.date == this.date) return;

      this.resetPage();
      this.$router.replace(r);
    },
    game_selected: async function(newSelected) {
      console.log(newSelected);
      await this.$nextTick();
      this.resetPage();
    }
  },
  computed: {
    ...mapGetters(["game"]),
    summaryDate: function() {
      if (this.date) {
        if (this.date.includes(" -> ")) {
          let ds = this.date.split(" -> ");
          return `${moment(ds[0], "DD-MM-YYYY").format("DD/MM")} -> ${moment(
            ds[1],
            "DD-MM-YYYY"
          ).format("DD/MM")}`;
        } else {
          let d = moment(this.date, "DD-MM-YYYY").format("YYYY-MM-DD");
          return moment(d, "DD-MM-YYYY").format("DD/MM");
        }
      }

      return this.date;
    },
    dateFromPicker: function() {
      let date = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      };

      if (this.date) {
        if (this.date.includes(" -> ")) {
          let ds = this.date.split(" -> ");
          date = {
            from: moment(ds[0], "DD-MM-YYYY").format("YYYY-MM-DD"),
            to: moment(ds[1], "DD-MM-YYYY").format("YYYY-MM-DD")
          };
        } else {
          let d = moment(this.date, "DD-MM-YYYY").format("YYYY-MM-DD");
          date = {
            from: d,
            to: d
          };
        }
      }
      return date;
    },
    gameSelectedText: function() {
      let vm = this;
      return this.games.filter(function(e) {
        return e.value == vm.game_selected;
      })[0].text;
    }
  },
  methods: {
    async fetchPlayerDetail($state) {
      let pid = this.$route.params.playerId;
      let uid = this.$route.params.userId;

      let params = { ...this.dateFromPicker, page: this.page };
      if (this.page == 1) params.summary = 1;
      if (this.game_selected) params.game = this.game_selected;

      let res = await this.$http.get("/report/" + uid + "/player/" + pid, {
        params: params
      });
      if (res.data.code == 0) {
        if (res.data.data.rp.length == 0) {
          $state.complete();
          return;
        }

        this.playerName = res.data.data.player;

        if (res.data.data.summary) {
          let total = {
            "2c-dd": {
              turn_over: res.data.data.summary.to_2cdd,
              turn_over_point: res.data.data.summary.top_2cdd,
              amount: res.data.data.summary.a_2cdd,
              win: res.data.data.summary.w_2cdd,
              win_point: res.data.data.summary.wp_2cdd
            },
            "2c-b": {
              turn_over: res.data.data.summary.to_2cb,
              turn_over_point: res.data.data.summary.top_2cb,
              amount: res.data.data.summary.a_2cb,
              win: res.data.data.summary.w_2cb,
              win_point: res.data.data.summary.wp_2cb
            },
            "3c": {
              turn_over: res.data.data.summary.to_3c,
              turn_over_point: res.data.data.summary.top_3c,
              amount: res.data.data.summary.a_3c,
              win: res.data.data.summary.w_3c,
              win_point: res.data.data.summary.wp_3c
            },
            "4c": {
              turn_over: res.data.data.summary.to_4c,
              turn_over_point: res.data.data.summary.top_4c,
              amount: res.data.data.summary.a_4c,
              win: res.data.data.summary.w_4c,
              win_point: res.data.data.summary.wp_4c
            },
            dat: {
              turn_over: res.data.data.summary.to_da,
              turn_over_point: res.data.data.summary.top_da,
              amount: res.data.data.summary.a_da,
              win: res.data.data.summary.w_da,
              win_point: res.data.data.summary.wp_da
            },
            dax: {
              turn_over: res.data.data.summary.to_dx,
              turn_over_point: res.data.data.summary.top_dx,
              amount: res.data.data.summary.a_dx,
              win: res.data.data.summary.w_dx,
              win_point: res.data.data.summary.wp_dx
            }
          };
          this.reportTotal = total;
          this.thu_tra = res.data.data.summary.winloss;
          this.total_sms = res.data.data.summary.total;
        }

        let d = res.data.data.rp.map(function(e) {
          return {
            id: e.id,
            sms: e.sms,
            number_win: e.number_win,
            detail: {
              "2c-dd": {
                turn_over: e.to_2cdd,
                turn_over_point: e.top_2cb,
                amount: e.a_2cdd,
                win: e.w_2cdd,
                win_point: e.wp_2cdd
              },
              "2c-b": {
                turn_over: e.to_2cb,
                turn_over_point: e.top_2cb,
                amount: e.a_2cb,
                win: e.w_2cb,
                win_point: e.wp_2cb
              },
              "3c": {
                turn_over: e.to_3c,
                turn_over_point: e.top_3c,
                amount: e.a_3c,
                win: e.w_3c,
                win_point: e.wp_3c
              },
              "4c": {
                turn_over: e.to_4c,
                turn_over_point: e.top_4c,
                amount: e.a_4c,
                win: e.w_4c,
                win_point: e.wp_4c
              },
              dat: {
                turn_over: e.to_da,
                turn_over_point: e.top_da,
                amount: e.a_da,
                win: e.w_da,
                win_point: e.wp_da
              },
              dax: {
                turn_over: e.to_dx,
                turn_over_point: e.top_dx,
                amount: e.a_dx,
                win: e.w_dx,
                win_point: e.wp_dx
              }
            }
          };
        });
        let data = [...this.reportData, ...d];
        this.reportData = data;
        this.page++;

        if (res.data.data.rp.length < 20) {
          $state.complete();
        } else {
          $state.loaded();
        }
      }
    },
    bindDate() {
      let date = this.$route.query.date
        ? this.$route.query.date
        : moment().format("DD-MM-YYYY");
      this.date = date;
    },
    bindGameOpts(games) {
      let g = Object.values(games).map(function(e) {
        return { value: e[0].id, text: e[0].alias };
      });
      g.unshift({ value: null, text: "Tất cả" });
      this.games = g;
    },
    resetPage() {
      this.page = 1;
      this.reportData = [];
      this.reportTotal = {};
      this.thu_tra = 0;
      this.infiniteId++;
    },
    formatMoney(money) {
      return Utilities.currencyReport(money);
    }
  },
  created() {
    this.bindDate();
    if (this.game) this.bindGameOpts(this.game);
  }
};
</script>
<style>
.report-detail .table th,
.report-detail .table td {
  border-top: none;
}

.report-detail .table thead th {
  border-bottom: 1px solid #dee2e6;
}

.report-detail .sms {
  white-space: pre-wrap !important;
  text-align: left;
  word-wrap: break-word;
}
.report-detail .title-sms {
  text-align: left;
}
</style>
