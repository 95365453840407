<template>
  <div class="report px-3 mt-4">
    <b-row align-v="center" class="mt-2">
      <b-col sm="5" cols="12" class="mb-2 text-right">
        <b-input-group size="sm" class="mw-50">
          <flat-pickr
            class="form-control form-control-sm"
            :config="configPickr"
            v-model="date"
          >
          </flat-pickr>
          <b-input-group-append>
            <button
              class="btn input-group-text"
              type="button"
              title="Toggle"
              data-toggle
            >
              <b-icon
                icon="calendar2-check"
                scale="1"
                variant="secondary"
              ></b-icon>
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-card-group deck>
      <b-card
        border-variant="primary"
        :header="index"
        header-bg-variant="primary"
        header-text-variant="white"
        align="center"
        no-body
        v-for="(item, index) in datas"
        :key="index"
      >
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            Tổng số:
            <b-badge variant="primary" pill>{{ item.total }}</b-badge>
          </b-list-group-item>
          <b-list-group-item>
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Tài khoản đăng nhập</h5>
              <small class="text-muted">{{ item.login.count }}</small>
            </div>
            <p class="mb-1">
              {{ item.login.detail.join(", ") }}
            </p>
          </b-list-group-item>
          <b-list-group-item>
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Số lượng sms</h5>
              <small class="text-muted">{{ item.sms.count }}</small>
            </div>
            <p class="mb-1">
              {{ item.sms.detail.join(", ") }}
            </p>
          </b-list-group-item>
          <b-list-group-item>
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Tài khoản sắp hết hạn</h5>
              <small class="text-muted">Nhỏ hơn 7 ngày</small>
            </div>
            <p class="mb-1">
              {{ item.expired.join(", ") }}
            </p>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import moment from "moment";

export default {
  //   computed:{
  //     ...mapGetters([
  //       'currentUser'
  //     ])
  //   },
  data() {
    return {
      configPickr: {
        allowInput: false,
        disableMobile: true,
        dateFormat: "Y-m-d",
        mode: "single",
        defaultDate: moment().format("YYYY-MM-DD"),
        wrap: true
      },
      date: null,
      datas: []
    };
  },
  watch: {
    date: async function(newDate) {
      this.getData(newDate);
    }
  },
  created() {
    // let firstPage = "guide";
    // if(this.currentUser && this.currentUser.role){
    //   firstPage = this.currentUser.role[0] == "agent" ? "users" : "betList";
    // }
    // this.$router.replace({name: firstPage});
    this.getData(moment().format("YYYY-MM-DD"));
  },
  methods: {
    async getData(date) {
      let res = await this.$http.get(
        "/dashboard?date=" + moment(date).format("YYYY-MM-DD")
      );
      this.datas = res.data.data;
    }
  }
};
</script>
