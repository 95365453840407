<template>
  <router-link :to="link" class="button-back d-sm-none">
    <b-button variant="outline-secondary_" class="btn_p25 fz12" size="sm">
      <b-icon icon="arrow-left"></b-icon>
    </b-button>
  </router-link>
</template>
<script>
export default {
  name: "button-back",
  props: ["link"],
  // props: {
  //   link: {
  //     type: Object,
  //     default: {},
  //     description: 'Button type (primary|secondary|danger etc)'
  //   },
  // },
  methods: {}
};
</script>
<style>
.button-back button {
  border-color: #ffffff;
  font-size: 16px;
}
.button-back .btn:focus,
.button-back .btn.focus {
  box-shadow: none;
}
.button-back .btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem 0.25rem 0rem;
}
</style>
